*:not(html) {
  @apply text-sm font-inter;

  // Scrollbar
  ::-webkit-scrollbar {
    @apply w-[6px];
  }
  
  ::-webkit-scrollbar-track {
    @apply bg-palette-gray-200 rounded-[3px];
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-[#c2c4c7] rounded-[3px];
  }
  
  ::-webkit-scrollbar-thumb:hover {
    @apply  bg-[#a3a6aa];
  }
}

*:not(.p-component *) {
  @apply text-palette-gray-600;
}

// Table
.p-table {
  & tr, & td, & th {
    @apply text-sm text-palette-gray-600;
  }
}

// Button
.p-button {
  @apply h-10 rounded-lg text-sm/none font-semibold py-2.5 px-[18px];

  &:enabled {
    &,
    &:hover,
    &:active,
    &:focus {
      @apply border-palette-blue-500 bg-palette-blue-500 shadow-none leading-6 text-palette-base-white;
    }
    & {
      @apply border-palette-blue-600 bg-palette-blue-600;
    }
  }

  &.p-button-secondary {
    &:enabled {
      &,
      &:hover,
      &:active,
      &:focus {
        @apply bg-palette-gray-200 border-palette-gray-300 text-palette-gray-700 shadow-none leading-6;
      }
      & {
        @apply bg-palette-base-white;
      }
    }
  }

  &.p-button-danger {
    &:enabled {
      &,
      &:hover,
      &:active,
      &:focus {
        @apply border-palette-red-500 bg-palette-red-500 shadow-none leading-6;
      }
      & {
        @apply border-palette-red-600 bg-palette-red-600;
      }
    }
  }
}

// Card
.p-card {
  .p-card-body, .p-card-content, .p-card-footer{
    @apply p-0;
  }
  
}

// Input
input, input .p-input, .p-inputtext {
  @apply h-11 text-base/5 text-palette-gray-700;
}

.p-inputtext {
  &:enabled {
    &:hover,
    &:focus {
      @apply border-palette-gray-300;
    }
  }
}

.p-inputtext {
  &:enabled {
    &:focus {
      @apply shadow-none;
    }
  }
}

// Dropdown
.p-dropdown {
  @apply w-full items-center justify-center;

  &, & .p-dropdown-trigger {
    @apply h-11;
  }

  &:not(.p-disabled) {
    &:hover {
      @apply border-palette-gray-300;
    }
    &.p-focus {
      @apply shadow-none border-palette-gray-300;
    }
  }

  & .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    @apply font-medium text-palette-gray-700;
  }

  & .p-dropdown-label.p-inputtext {
    @apply flex items-center px-2;
  }
}

// Multiselect
.p-multiselect {
  @apply rounded-lg h-11;

  .p-multiselect-label {
    @apply flex items-center text-base/5 text-palette-gray-700;
  }

  .p-multiselect-item {
    @apply font-medium;
  }

  .p-multiselect-items {
    padding: 0;
  }
}

// Overlay
.p-overlay {
  & > .p-overlay-content {
    @apply shadow-lg rounded-lg;
  }
}

// Tag
p-tag {
  @apply rounded-2xl px-[2px] py-2;

  & * {
    @apply text-xs/[18px] font-medium;
  }
}

// Calendar
p-calendar, .p-calendar {
  @apply w-full h-11;

  & .p-button {
    @apply h-11;

    &:enabled {
      &,
      &:hover,
      &:active,
      &:focus {
        @apply bg-palette-gray-200 border-palette-gray-300 text-palette-gray-700 shadow-none leading-6;
      }
      & {
        @apply bg-palette-base-white;
      }
    }
  }
  & .p-inputtext {
    @apply border-r-0;
  }

  & .p-button.p-datepicker-trigger {
    @apply border-l-0;
  }
}

// Tab panel
.p-tabview {
  & .p-tabview-panels {
    @apply bg-palette-gray-200 p-0;
  }

  & .p-tabview-nav {
    @apply rounded-lg rounded-b-none;
    & li {
      & .p-tabview-nav-link span {
        @apply font-semibold text-base text-palette-gray-500;
      }

      &.p-highlight .p-tabview-nav-link span {
        @apply bg-palette-base-white border-palette-blue-700 text-palette-blue-700;
      }
      
      & .p-tabview-nav-link:not(.p-disabled):focus {
        @apply shadow-none;
      }
    }
  }
}

// scrollbar-gutter

.scrollbar-gutter{
  scrollbar-gutter: stable both-edges;
}